export const getLocation = (development, all = false) => {
  if (development) {
    if (development.location && all) {
      return [
        development.location.full_location.split(" | ")[3],
        development.location.full_location.split(" | ")[2],
      ]
    }
    if (development.location) {
      return development.location.full_location.split(" | ")[1]
    }
  }
  return ["Sin Ubicación", "Sin Ubicación"]
}



export const getFakeAddress = development => {
  return development?.fake_address
}
export const getName = development => {
  return development?.name
}
export const getFeatured = developments => {
  return developments?.filter(development => development.is_starred_on_web).sort((a,b) => b.id - a.id)
}
export const getTitle = development => {
  if (development?.publication_title) {
    return development.publication_title
  }
}
export const getDescription = development => {
  if (development?.description) {
    return development.description
  }
}
export const getStatus = development => {
  if (development?.construction_status) {
    if (development.construction_status === 1) {
      return "Desconocido"
    }if (development.construction_status === 3) {
      return "En pozo"
    }
    if (development.construction_status === 4) {
      return "En Construcción"
    }
    if (development.construction_status === 6) {
      return "Construcción Terminada"
    } else {
      return development.construction_status
    }
  }
}
export const getType = development => {
  if (development?.type) {
    return development.type.name
  }
  return ""
}
export const getContructionDate = development => {
  let month = ""
  if (development?.construction_date) {
    switch (development.construction_date.split("-")[1]) {
      case "01":
        month = "Enero"
        break
      case "02":
        month = "Febrero"
        break
      case "03":
        month = "Marzo"
        break
      case "04":
        month = "Abril"
        break
      case "05":
        month = "Mayo"
        break
      case "06":
        month = "Junio"
        break
      case "07":
        month = "Julio"
        break
      case "08":
        month = "Agosto"
        break
      case "09":
        month = "Septiembre"
        break
      case "10":
        month = "Octubre"
        break
      case "11":
        month = "Noviembre"
        break
      case "12":
        month = "Diciembre"
        break
    }
    return month + " de " + development.construction_date.split("-")[0]
  }
}
export const group_Unities = (data, filter = 0, ids = []) => {
  const array = []
  for (let i = 0; i < data.length; i++) {
    // Armo la nueva unidad en un objeto, separo piso de la unidad en el titulo
    if (filter === data[i].room_amount || filter === 0) {
      let object = {}
      if (data[i].id !== 741886) {
        object = {
          floor: data[i].real_address
            .split("-")[0]
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          unity: {
            fake_address: data[i].fake_address,
            address: data[i].real_address.split("-")[1],
            // address:data[i].real_address.replace(data[i].real_address.split("-")[0]+"-",''),
            id: data[i].id,
            status: data[i]?.status,
            type: data[i]?.type?.name,
            location: data[i]?.location.name,
            surface: data[i]?.roofed_surface,
            surface_total: data[i].total_surface,
            rooms: data[i].room_amount,
            web_price:data[i].web_price,
            operations:data[i].operations.map((op,index) => (
              {...op,prices:op.prices[0]}
            )),
            price: data[i].web_price
              ? data[i]?.operations[0]?.prices[0]?.price
              : "A consultar",
            previous_price: data[i]?.operations[0]?.prices[0]
              ?.is_less_than_previous_price
              ? data[i]?.operations[0]?.prices[0].previous_price
              : 0,
            currency: data[i].web_price
              ? data[i]?.operations[0]?.prices[0]?.currency
              : "",
          },
        }
      }

      // // Verifico si existe el piso o lo tengo que crear
      const finder = array.find(element => element.floor === object.floor)
      if (array.find(element => element.floor === object.floor)) {
        if (
          (ids.length > 0 && ids.indexOf(data[i].id.toString()) !== -1) ||
          ids.length === 0
        ) {
          finder.unities.push(object.unity)
        }
      } else {
        if (
          (ids.length > 0 && ids.indexOf(data[i].id.toString()) !== -1) ||
          ids.length === 0
        ) {
          array.push({
            floor: object.floor
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
            unities: [object.unity],
          })
        }
      }
    }
  }

  let pisos = []
  let area = []
  let subsuelos = []
  let nivel = []
  let duplex = []
  let barrio = []

  let response = []

  for (const iterator of array) {
    if (iterator.floor.split(" ")[0].toLowerCase().includes("piso")) {
      if (iterator.floor.includes("PB")) {
        pisos.unshift(iterator)
      } else {
        pisos.push(iterator)
      }
    }
    if (iterator.floor.split(" ")[0].toLowerCase().includes("subsuelo")) {
      subsuelos.push(iterator)
    }
    if (
      iterator.floor
        .split(" ")[0]
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes("area")
    ) {
      area.push(iterator)
    }
    if (iterator.floor.split(" ")[0].toLowerCase().includes("nivel")) {
      nivel.push(iterator)
    }
    if (iterator.floor.split(" ")[0].toLowerCase().includes("dúplex")) {
      duplex.push(iterator)
    }
    if (iterator.floor.split(" ")[0].toLowerCase().includes("barrio")) {
      barrio.push(iterator)
    }
  }

  pisos = pisos.sort(function (a, b) {
    return a.floor.split(" ")[1] - b.floor.split(" ")[1]
  })
  area = area.sort(function (a, b) {
    return a.floor.split(" ")[1] - b.floor.split(" ")[1]
  })
  subsuelos = subsuelos.sort(function (a, b) {
    return a.floor.split(" ")[1] - b.floor.split(" ")[1]
  })
  duplex = duplex.sort(function (a, b) {
    return a.floor.split(" ")[1] - b.floor.split(" ")[1]
  })
  barrio = barrio.sort(function (a, b) {
    return a.floor.split(" ")[1] - b.floor.split(" ")[1]
  })

  for (const iterator of subsuelos) {
    response.push(iterator)
  }
  for (const iterator of nivel) {
    response.push(iterator)
  }
  for (const iterator of pisos) {
    response.push(iterator)
  }
  for (const iterator of area) {
    response.push(iterator)
  }
  for (const iterator of duplex) {
    response.push(iterator)
  }
  for (const iterator of barrio) {
    response.push(iterator)
  }

  console.log(response)

  return response
  // return array.sort(function(a, b){return a.floor - b.floor});
}
export const is_Sold = development => {
  if (development?.custom_tags) {
    for (const iterator of development?.custom_tags) {
      if (iterator.name === "100vendido") {
        return true
      }
    }
  }
  return false
}
export const getLastFeatured = developments => {
  return developments
    ?.filter(development => development?.is_starred_on_web)
    .slice(-1)[0]
}
export const getReverseOrder = developments => {
  return developments
  // return developments?.sort((a,b) => b.id - a.id);
}
export const getCustomTags = (developments, group) => {
  const custom_tags = []
  if (developments) {
    const developments_filtered = developments?.filter(
      element =>
        !element.custom_tags.find(tag => tag.name.toLowerCase() === "portada")
    )
    for (const development of developments_filtered) {
      for (const tag of development.custom_tags) {
        if (
          tag.group_name === group &&
          tag.name.toLowerCase() !== "portada" &&
          custom_tags.filter(element => element.name === tag.name).length === 0
        ) {
          custom_tags.push(tag)
        }
      }
    }
  }
  return custom_tags
}

export const getGroupDevelopment = (developments, group) => {
  if (developments) {
    const developments_filtered = developments?.filter(element =>
      element.custom_tags.find(tag => tag.name.toLowerCase() === "portada")
    )
    return developments_filtered
  }
  return []
}


export const shortLocation = (full_location,position) => {
  var split = full_location.split("|")
  return split[position]
}

export const getPricesUnityPrice = (unity_development) => {
  var valueUnity = [];
  if(unity_development){
    for (const iterator of unity_development) {
      valueUnity.push(iterator.operations[0].prices[0].price);
    }
    return [Math.min.apply(null, valueUnity), Math.max.apply(null, valueUnity)];
  }
}