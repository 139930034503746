import { adapt_development_data } from "../../helpers/helper.mediacore"
import { mediacoreApi } from "../mediacoreApi"

const developmentsExtended = mediacoreApi.injectEndpoints({
  endpoints: builder => ({
    getDevelopments: builder.query({
      query: ({CLIENT_ID,PARAMS}) =>
        `site/desarrollos/template/?client=${CLIENT_ID}&${PARAMS}`,
        // transformResponse: (data) => ({"meta":{"total_count":data.length},"objects": data.map((prop => adapt_development_data(prop)))})
        transformResponse: (data) => (data.map((prop => adapt_development_data(prop))))
    }),
    getDevelopment: builder.query({
      query: ({CLIENT_ID,DEVELOPMENT_ID}) =>
        `site/desarrollos/${DEVELOPMENT_ID}/ficha/?client=${CLIENT_ID}&with_units=true`,
        
        transformResponse: (data) => (adapt_development_data(data))
    }),
  }),
  overrideExisting: false,
})

export const { useGetDevelopmentsQuery, useGetDevelopmentQuery } = developmentsExtended
